import React from 'react';
import { closeModal, openModal } from '@redq/reuse-modal';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import GatsbyImage from 'common/components/GatsbyImage';
import Section, {
  BannerContentWrapper,
  BannerContent,
  Subscribe,
  Figure,
} from './banner.style';
import envelope from 'common/assets/image/webAppCreative/icons/envelope.png';
import ContactModal from '../ContactModal';

const Banner = () => {

  // Default close button for modal
  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  // Authentication modal handler
  const handleContactModal = (event) => {
    if (event) event.preventDefault();

    const emailGiven = document.getElementsByClassName('emailInputForContact')[0].value;
    
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: ContactModal,
      componentProps: {
        emailGiven: emailGiven
      },
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  const dashboard = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/webAppCreative/loobi-pro-dashboard-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home">
      <Container width="1400px">
        <BannerContentWrapper>
          <BannerContent>
            <Heading
              className="animate__animated animate__fadeInUp"
              content="Céntrate en lo que mejor sabes hacer, déjanos el resto"
            />
            <p class="Text__TextWrapper-fKLFqX eFDLcJ animate__animated animate__fadeInUp"><strong style={{color: '#806ED3'}}>Ahorra</strong> hasta <strong style={{color: '#806ED3'}}>24h/mes</strong> e <strong style={{color: '#806ED3'}}>incrementa las ventas</strong> hasta un <strong style={{color: '#806ED3'}}>30%</strong> dejando reservas, pedidos, facturación, captación y fidelización a Loobi.</p>
            <Subscribe className="animate__animated animate__fadeInUp">
              <Input
                inputType="email"
                placeholder="Tu e-mail"
                iconPosition="left"
                aria-label="email"
                class="emailInputForContact"
                icon={<Image src={envelope} alt="envelope" />}
              />
              <Button title="Pruébalo gratis" type="button" onClick={handleContactModal} />
            </Subscribe>
          </BannerContent>
          <Figure className="animate__animated animate__fadeInUp animate__fast">
            <GatsbyImage
              src={
                (dashboard.illustration !== null) | undefined
                  ? dashboard.illustration.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="dashboard"
            />
          </Figure>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
