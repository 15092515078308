import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/webAppCreative';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/WebAppCreative/Navbar';
import Banner from 'containers/WebAppCreative/Banner';
import Clients from 'containers/WebAppCreative/Clients';
import HowItWorks from 'containers/WebAppCreative/HowItWorks';
import AnalyticsTool from 'containers/WebAppCreative/AnalyticsTool';
import Dashboard from 'containers/WebAppCreative/Dashboard';
import Testimonials from 'containers/WebAppCreative/Testimonials';
import Integrations from 'containers/WebAppCreative/Integrations';
import Pricing from 'containers/WebAppCreative/Pricing';
import NewsFeed from 'containers/WebAppCreative/NewsFeed';
import Faq from 'containers/WebAppCreative/Faq';
import CallToAction from 'containers/WebAppCreative/CallToAction';
import Footer from 'containers/WebAppCreative/Footer';
import Seo from 'components/seo';
import Helmet from 'react-helmet';
import {
  GlobalStyle,
  ContentWrapper,
  CombinedSection,
  CornerPattern,
} from 'containers/WebAppCreative/webAppCreative.style';

import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';

const WebAppCreative = () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="Céntrate en lo que mejor sabes hacer, nosotros hacemos el resto" />
        <Helmet>
          <link rel="canonical" href="https://www.loobi.com/negocios/"/>
          <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/a370565f2585f5f2f12fe533/script.js"/>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/143736459.js"></script>
        </Helmet>
        <Modal />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          {/*<Clients />*/}
          <HowItWorks />
          <AnalyticsTool />
          <Dashboard />
          {/*<Testimonials />*/}
          <CombinedSection>
            <Integrations />
            {/*<Pricing />*/}
            <CornerPattern />
          </CombinedSection>
          {/*<NewsFeed />*/}
          <Faq />
          <CallToAction />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default WebAppCreative;
