import React, {useEffect} from "react";
import HubspotContactFormWrapper from './HubspotContatForm.style';
    
const HubspotContactForm = props => {
  const { region, portalId, formId, emailGiven } = props;
  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
          onFormReady: function (form) {
            var emailField = form.querySelector('input[name="email"]');
            
            if (emailField && emailGiven) {
              emailField.value = emailGiven;
            }
          }
        })
      }
    });
  }, []);

  return (
    <HubspotContactFormWrapper>
      <div id="hubspotForm"></div>
    </HubspotContactFormWrapper>
  );
};

export default HubspotContactForm;