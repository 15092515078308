import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ContactModalWrapper = styled.div`
  width: 80%;
  margin: 71px auto;
  border-radius: 5px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (min-width: 1201px) {
    max-width: 1170px;
    width: 100%;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
  }
  .col {
    position: relative;
    .patternImage {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      &.imageCol {
        display: none;
      }
    }
  }
`;

export default ContactModalWrapper;
