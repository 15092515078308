import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { androidArrowDropdown } from 'react-icons-kit/ionicons/androidArrowDropdown';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import Container from 'common/components/UI/Container';
import Button from 'common/components/Button';
import Link from 'common/components/Link';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Section, { Content, DownloadButton, DownloadOptions } from './cta.style';
import bubble1 from 'common/assets/image/webAppCreative/cta-bubble-1.png';
import bubble2 from 'common/assets/image/webAppCreative/cta-bubble-2.png';
import { closeModal, openModal } from '@redq/reuse-modal';
import ContactModal from '../ContactModal';

const menuItemWords = ['Download for MacOS', 'Download for Windows x64'];

const CallToAction = () => {
  // Default close button for modal
  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  // Authentication modal handler
  const handleContactModal = (event) => {
    if (event) event.preventDefault();

    const emailGiven = document.getElementsByClassName('emailInputForContact')[0].value;
    
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: ContactModal,
      componentProps: {
        emailGiven: emailGiven
      },
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <Section>
      <img src={bubble1} className="bubble-1" alt="bubble1" />
      <Container width="1400px">
        <Content>
          <Heading content="Comienza ahora a ahorrar tiempo y céntrate en tu negocio" />
          <Text content="En menos de 10 minutos tendras representación digital de tu negocio en nuestro marketplace y disfrutaras de todas las ventajas desde el minuto 0" />
          <Link href="#" className="explore" onClick={handleContactModal}>
            <Button
              title="Pruébalo Gratis"
              icon={<Icon icon={ic_keyboard_arrow_right} size={24} />}
            />
          </Link>
        </Content>
      </Container>
      <img src={bubble2} className="bubble-2" alt="bubble2" />
    </Section>
  );
};

export default CallToAction;
